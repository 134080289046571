.projects-wrapper {
	.project-item:nth-child(even) {
		background-color: $white;
		border-radius: 6px;
		border: 2px solid #ccc;
	}
}

.project-item {

	padding: 40px 20px 25px;

	> *:first-child { margin-top: 0; }
	> *:last-child { margin-bottom: 0; }

	.gallery { line-height: 0; }

}