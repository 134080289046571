.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 1200px) {
    .container-fluid {
      max-width: 1140px;
      margin: 0 auto; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.wrap {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }
  html *,
  html *:before,
  html *:after {
    box-sizing: inherit; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #f5f5f5;
  color: #444; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

body {
  min-width: 360px;
  background-color: #f5f5f5; }
  @media (min-width: 768px) {
    body:not(.page_front) {
      padding-bottom: 145px;
      height: 100%; }
      body:not(.page_front) .site_info {
        position: absolute;
        bottom: 0;
        width: 100%; } }

html {
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: -0.0275em;
  line-height: 1.5em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 992px) {
    html {
      font-size: 17px;
      font-size: 1.0625rem; } }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h5, .h5,
h6, .h6, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 30px 0 15px;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -0.0425em; }
  h5 small, .h5 small, h6 small, .h6 small, h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, h1 small, .h1 small {
    display: block;
    font-size: 75%;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: -0.0125em;
    line-height: 1em;
    color: #888; }

h5, .h5,
h6, .h6 {
  font-size: 16px;
  font-size: 1rem; }
  @media (min-width: 992px) {
    h5, .h5,
    h6, .h6 {
      font-size: 17px;
      font-size: 1.0625rem; } }

h4, .h4 {
  font-size: 18px;
  font-size: 1.125rem; }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 20px;
      font-size: 1.25rem; } }

h3, .h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: -0.0525em; }
  h3 small, .h3 small {
    font-weight: 600; }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 23px;
      font-size: 1.4375rem; } }

h2, .h2 {
  font-size: 22px;
  font-size: 1.375rem; }
  @media (min-width: 992px) {
    h2, .h2 {
      font-size: 27px;
      font-size: 1.6875rem; } }

h1, .h1 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 800;
  letter-spacing: -0.0525em; }
  h1 small, .h1 small {
    font-weight: 600; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 36px;
      font-size: 2.25rem; } }

p {
  margin: 0 0 15px; }
  p.lead {
    font-size: 118%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin: 0 0 15px;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #444; }
  blockquote.bq--primary {
    border-color: #33439D; }
  blockquote.bq--secondary {
    border-color: #C80130; }
  blockquote.bq--highlight {
    border-color: #ff6600; }
  blockquote.bq--accent {
    border-color: #4d7ea8; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #cc2936; }
  blockquote.bq--warning {
    border-color: #d39e00; }
  blockquote.bq--info {
    border-color: #87bcde; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq--title {
  font-weight: 800;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq--title {
    color: #444; }
  .bq--primary .bq--title {
    color: #33439D; }
  .bq--secondary .bq--title {
    color: #C80130; }
  .bq--highlight .bq--title {
    color: #ff6600; }
  .bq--accent .bq--title {
    color: #4d7ea8; }
  .bq--success .bq--title {
    color: #28a745; }
  .bq--danger .bq--title {
    color: #cc2936; }
  .bq--warning .bq--title {
    color: #d39e00; }
  .bq--info .bq--title {
    color: #87bcde; }

blockquote.bq-alt {
  margin-right: 5em;
  padding: 1.5em;
  border-radius: 6px;
  border: 4px solid #444;
  background-color: #fff; }
  blockquote.bq-alt.bq--primary {
    border-color: #33439D; }
  blockquote.bq-alt.bq--secondary {
    border-color: #C80130; }
  blockquote.bq-alt.bq--highlight {
    border-color: #ff6600; }
  blockquote.bq-alt.bq--accent {
    border-color: #4d7ea8; }
  blockquote.bq-alt.bq--success {
    border-color: #28a745; }
  blockquote.bq-alt.bq--danger {
    border-color: #cc2936; }
  blockquote.bq-alt.bq--warning {
    border-color: #ffc107; }
  blockquote.bq-alt.bq--info {
    border-color: #87bcde; }

hr {
  display: block;
  clear: both;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: none;
  background-color: #bbb; }
  hr.hr-inset {
    margin: 2em auto;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }
  .content_text hr {
    margin: 1em 0 !important; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #33439D; }
  a:hover, a:focus {
    color: #4255c1;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #fe4a75; }
  a.mask {
    text-decoration: none;
    color: inherit; }
    a.mask:hover {
      color: inherit; }

a.img-link {
  text-decoration: none; }

a.phone {
  text-decoration: none;
  color: #33439D; }
  a.phone:hover, a.phone:focus {
    cursor: default;
    color: #33439D; }
  a.phone.mask {
    color: #444; }
    a.phone.mask:hover, a.phone.mask:focus {
      color: #444; }

.list {
  margin: 0 0 15px;
  padding: 0 0 0 18px; }
  .list li:not(:last-child) {
    margin-bottom: 8px; }

li > ul {
  margin-top: 8px !important; }

.list-inline, .nav, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

.list-wrap {
  margin-bottom: 15px; }
  .list-wrap ul {
    margin: 0; }
  .list-wrap ul + ul {
    margin-top: 5px; }
  @media (min-width: 768px) {
    .list-wrap {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap; }
      .list-wrap ul {
        line-height: 1.25em; }
        .list-wrap ul li:not(:last-child) {
          margin-bottom: 8px; }
      .list-wrap ul + ul {
        margin-top: 0;
        padding-left: 40px; }
      .list-wrap.l2 ul {
        width: 50%; }
      .list-wrap.l3 ul {
        width: 33.33333%; }
      .list-wrap.l4 ul {
        width: 25%; } }

.--has-icon {
  vertical-align: middle; }
  .--has-icon > * {
    vertical-align: middle; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 0;
  vertical-align: middle; }

/* .icon-12 { width: 12px; height: 12px; }
.icon-16 { width: 16px; height: 16px; }

.icon-18 { width: 18px; height: 18px; }
.icon-64 { width: 64px; height: 64px; }*/
.icon-primary {
  fill: #33439D; }

.icon-secondary {
  fill: #C80130; }

.icon-star {
  fill: #ff9900; }

.icon-white {
  fill: #fff; }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img-inline {
  display: inline; }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 48%;
    margin: 0 0 15px 20px;
    max-width: none; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 48%;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 10px 5px 2px;
    font-size: 14px;
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.325em; }

div.img-captioned {
  max-width: 360px; }
  div.img-captioned img {
    width: 100%; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767.98px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.btn {
  display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms; }
  .btn:hover, .btn:focus {
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
    cursor: pointer; }
  .btn.btn--sm {
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 8px 12px; }
  .btn.btn--lg {
    font-size: 17px;
    font-size: 1.0625rem;
    padding: 15px 20px; }
  .btn.btn--wide {
    display: block;
    width: 100%; }
  .btn small {
    display: block; }

.btn:not(.btn-social) {
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95); }
  .btn:not(.btn-social):hover, .btn:not(.btn-social):focus {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }

.--solid-primary {
  border: 1px solid #2a3780;
  background-color: #2d3b8a;
  color: #fff !important; }
  .--solid-primary:hover, .--solid-primary:focus {
    border-color: #5566c8;
    background-color: #394bb0;
    color: #fff !important; }

.--solid-secondary {
  border: 1px solid #a20127;
  background-color: #af012a;
  color: #fff !important; }
  .--solid-secondary:hover, .--solid-secondary:focus {
    border-color: #fe184e;
    background-color: #e10136;
    color: #fff !important; }

.--solid-highlight {
  border: 1px solid #d95700;
  background-color: #e65c00;
  color: #fff !important; }
  .--solid-highlight:hover, .--solid-highlight:focus {
    border-color: #ff944d;
    background-color: #ff751a;
    color: #fff !important; }

.--solid-accent {
  border: 1px solid #416a8e;
  background-color: #457197;
  color: #fff !important; }
  .--solid-accent:hover, .--solid-accent:focus {
    border-color: #7ea3c4;
    background-color: #5b8bb4;
    color: #fff !important; }

.--solid-review {
  border: 1px solid #d98200;
  background-color: #e68a00;
  color: #fff !important; }
  .--solid-review:hover, .--solid-review:focus {
    border-color: #ffb84d;
    background-color: #ffa31a;
    color: #fff !important; }

.--solid-success {
  border: 1px solid #218838;
  background-color: #23923d;
  color: #fff !important; }
  .--solid-success:hover, .--solid-success:focus {
    border-color: #48d368;
    background-color: #2dbc4e;
    color: #fff !important; }

.--solid-danger {
  border: 1px solid #ac232e;
  background-color: #b72530;
  color: #fff !important; }
  .--solid-danger:hover, .--solid-danger:focus {
    border-color: #df626c;
    background-color: #d73844;
    color: #fff !important; }

.--solid-warning {
  border: 1px solid #e0a800;
  background-color: #edb100;
  color: #fff !important; }
  .--solid-warning:hover, .--solid-warning:focus {
    border-color: #ffd454;
    background-color: #ffc721;
    color: #fff !important; }

.--solid-info {
  border: 1px solid #69abd6;
  background-color: #73b1d9;
  color: #fff !important; }
  .--solid-info:hover, .--solid-info:focus {
    border-color: #c3deef;
    background-color: #9bc7e4;
    color: #fff !important; }

.button_close {
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #a2202b;
  background-color: #cc2936;
  background-image: url("/_/images/icons/close.png");
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer; }
  .button_close:hover {
    border-color: #cc2936;
    background-color: #db4d58; }

.btn-mobile {
  width: 48px;
  height: 41px;
  padding: 10px 11px 9px;
  background-color: #C80130;
  border: none;
  border-radius: 4px;
  color: #fff; }
  .btn-mobile:hover, .btn-mobile:focus {
    outline: none;
    background-color: #fe3161;
    cursor: pointer; }
  .btn-mobile b {
    display: none; }

.button-bars {
  display: inline-block;
  height: 100%;
  width: 26px; }

.icon-bar {
  -webkit-transition: transform ease-in-out 300ms;
  -moz-transition: transform ease-in-out 300ms;
  -ms-transition: transform ease-in-out 300ms;
  -o-transition: transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms;
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background-color: #fff; }
  .icon-bar:nth-child(2) {
    margin: 5px 0; }

.canvas-slid .icon-bar:nth-child(2) {
  visibility: hidden; }

.canvas-slid .icon-bar:nth-child(1), .canvas-slid .icon-bar:nth-child(3) {
  -webkit-transition: transform ease-in-out 150ms;
  -moz-transition: transform ease-in-out 150ms;
  -ms-transition: transform ease-in-out 150ms;
  -o-transition: transform ease-in-out 150ms;
  transition: transform ease-in-out 150ms; }

.canvas-slid .icon-bar:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 9px; }

.canvas-slid .icon-bar:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -16px; }

.gmb-button-review {
  display: flex;
  box-sizing: border-box;
  flex-wrap: 1;
  width: 240px;
  height: 61px;
  padding: 0 8px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em;
  text-decoration: none;
  border-radius: 6px;
  border: 0.075em solid #fff;
  background-image: linear-gradient(to right, #fff 50%, #4285f4 50%);
  color: #fff !important; }
  .gmb-button-review svg {
    align-self: center; }
  .gmb-button-review div {
    display: flex;
    align-items: center;
    height: 59px;
    margin-left: 8px;
    padding: 10px;
    text-align: left;
    background-color: #4285F4; }

.review-icon.icon-g {
  width: 40px;
  height: 41px; }

.review-icon.icon-bbb {
  width: 240px;
  height: 61px; }

.btn-social.--facebook {
  border: 1px solid #385796;
  background-color: #3b5c9f;
  color: #fff !important; }
  .btn-social.--facebook:hover, .btn-social.--facebook:focus {
    border-color: #7591cc;
    background-color: #5074be;
    color: #fff !important; }

.coverage {
  line-height: 1.275em; }
  .coverage > *:last-child {
    margin-bottom: 0; }
  .coverage p {
    margin-bottom: 8px; }

.coverage_title {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 800; }

.coverage_county {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600; }

.coverage_city {
  font-size: 13px;
  font-size: 0.8125rem; }

.form {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form_section {
  overflow: hidden;
  margin: 0 0 20px;
  padding: 15px;
  border: 1px solid #ccc; }
  .form_section hr {
    margin: 15px 0 20px;
    background-color: #c5c5c5; }
  @media (min-width: 768px) {
    .form_section .form--left,
    .form_section .form_city {
      float: left;
      clear: both;
      width: 49%; }
    .form_section .form--right {
      float: right;
      width: 49%; }
    .form_section .form_state {
      float: left;
      width: 23.5%;
      margin: 0 2%; }
    .form_section .form_zip {
      float: right;
      width: 23.5%; }
    .form_section .form--clear {
      clear: both; }
    .form_section .checkbox_group {
      display: flex;
      flex-wrap: wrap; }
      .form_section .checkbox_group .form_checkbox {
        width: 50%; }
    .form_section .form_radio {
      display: flex;
      flex-wrap: wrap; }
      .form_section .form_radio label {
        margin-right: 15px; } }

.section-title {
  padding: 5px 45px 5px 10px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.0475em; }

.section-subtitle {
  margin: 10px 0;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: none;
  letter-spacing: -0.0475em;
  color: #444; }

.form-title {
  margin: 0 0 10px;
  font-weight: 600; }
  .form-title small {
    font-size: 15px;
    font-size: 0.9375rem; }

.form_group {
  position: relative;
  margin: 0 0 5px; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }

label {
  display: block;
  margin: 10px 0 3px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1em; }

.form_control {
  display: block;
  width: 100%;
  padding: 5px;
  line-height: 1em;
  border: 1px solid #ccc;
  background-color: #fff;
  outline: none; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px;
  line-height: 1.275em; }

.form_radio,
.form_checkbox {
  vertical-align: top;
  line-height: 1em; }
  .form_radio label,
  .form_checkbox label {
    display: block;
    clear: both;
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1em;
    cursor: pointer; }
  .form_radio .checkbox, .form_radio .radio,
  .form_checkbox .checkbox,
  .form_checkbox .radio {
    display: block;
    margin: 0 15px 0 0;
    float: left;
    width: 20px;
    height: 20px; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #cc2936 !important; }

.error-message {
  margin: -5px 0 0;
  padding: 6px 4px 4px;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #cc2936;
  color: #fff; }

.form_spam {
  position: relative;
  height: 61px; }
  .form_spam .g-recaptcha {
    position: absolute;
    top: -8px;
    left: -31px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8); }

.form.form_jobs {
  border-radius: none;
  border: none; }
  .form.form_jobs .form_section {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.7) 85%, transparent); }

.form_quick {
  padding: 20px;
  background-color: #fff; }
  .page_contact .form_quick {
    background-color: transparent;
    padding: 0; }

.form_quote .form_group.form_radio label {
  margin-bottom: 10px;
  padding: 5px; }
  .form_quote .form_group.form_radio label .radio {
    margin-top: -3px; }

.form_quote .checkbox_group .form_group label {
  padding: 5px; }

.form_quote .checkbox_group .form_group .checkbox {
  margin-top: -2px; }

.gallery {
  overflow: hidden;
  margin-bottom: 1em; }
  .gallery img:not(:last-child) {
    margin-bottom: 15px; }

@media (min-width: 768px) and (max-width: 991.98px) {
  .g3-md > img, .g3-md > a, .g3-md > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2), .g3-md > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n), .g3-md > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

.gallery {
  overflow: hidden;
  margin-bottom: 1em; }

.gallery_lightbox > a {
  display: block;
  transition: all ease-in-out 300ms;
  overflow: hidden;
  margin-bottom: 2%;
  border: 3px solid transparent; }
  .gallery_lightbox > a img {
    display: block;
    width: 100%;
    transition: transform ease-in-out 200ms; }
  .gallery_lightbox > a:hover {
    transition: all ease-in-out 300ms;
    border-color: #C80130; }
    .gallery_lightbox > a:hover img {
      transition: transform ease-in-out 200ms;
      transform: scale(1.2); }

@media (max-width: 767.98px) {
  .g2 > img, .g2 > a, .g2 > .img-captioned, .g2 > picture {
    display: block;
    float: left;
    width: 49.5%;
    margin-right: 1%; }
    .g2 > img:nth-child(2n-1), .g2 > a:nth-child(2n-1), .g2 > .img-captioned:nth-child(2n-1), .g2 > picture:nth-child(2n-1) {
      clear: both; }
    .g2 > img:nth-child(2n), .g2 > a:nth-child(2n), .g2 > .img-captioned:nth-child(2n), .g2 > picture:nth-child(2n) {
      float: right;
      margin-right: 0; } }

@media (max-width: 767.98px) {
  .g3 > img, .g3 > a, .g3 > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3 > img:nth-child(3n-2), .g3 > a:nth-child(3n-2), .g3 > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3 > img:nth-child(3n), .g3 > a:nth-child(3n), .g3 > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .g4-md-only > img, .g4-md-only > a, .g4-md-only > .img-captioned, .g4-md-only > picture {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-md-only > img:nth-child(4n-3), .g4-md-only > a:nth-child(4n-3), .g4-md-only > .img-captioned:nth-child(4n-3), .g4-md-only > picture:nth-child(4n-3) {
      clear: both; }
    .g4-md-only > img:nth-child(4n), .g4-md-only > a:nth-child(4n), .g4-md-only > .img-captioned:nth-child(4n), .g4-md-only > picture:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) {
  .g6-md > img, .g6-md > a, .g6-md > .img-captioned {
    display: block;
    float: left;
    width: 15.83333%;
    margin-right: 1%; }
    .g6-md > img:nth-child(6n-5), .g6-md > a:nth-child(6n-5), .g6-md > .img-captioned:nth-child(6n-5) {
      clear: both; }
    .g6-md > img:nth-child(6n), .g6-md > a:nth-child(6n), .g6-md > .img-captioned:nth-child(6n) {
      float: right;
      margin-right: 0; } }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99500;
  text-align: center;
  line-height: 0;
  font-weight: 400; }
  @media (min-width: 768px) {
    .lightbox {
      margin-top: 75px; } }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 3px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/icons/lb_prev.png") left 48% no-repeat;
  background-size: 28px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/icons/lb_next.png") right 48% no-repeat;
  background-size: 28px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-top: 3px solid #fff;
  background-color: #fff;
  background-color: transparent;
  color: #444; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close.png") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.nav {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.nav-item {
  box-sizing: border-box;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  .nav-item.active > .nav-link {
    cursor: default;
    font-weight: 800; }
    .nav-item.active > .nav-link:hover {
      cursor: default; }
  .nav-item.open {
    position: relative; }
    .nav-item.open .dropdown-nav {
      display: block !important;
      height: auto; }
    .nav-item.open .caret {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); }
      .nav-item.open .caret.--push {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg); }

.nav-link {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .nav-link:hover, .nav-link:focus {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    outline: none; }
    .nav-link:hover .caret.--push, .nav-link:focus .caret.--push {
      -webkit-transition: all ease-in-out 300ms;
      -moz-transition: all ease-in-out 300ms;
      -ms-transition: all ease-in-out 300ms;
      -o-transition: all ease-in-out 300ms;
      transition: all ease-in-out 300ms;
      width: 10px;
      margin-right: 3px; }

.caret {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  top: 4px;
  vertical-align: top;
  fill: #444; }
  .caret.--push {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    margin-right: 0;
    width: 0; }

.dropdown-nav {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0; }
  @media (min-width: 768px) {
    .dropdown-nav {
      position: absolute; } }

.nav-main {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  line-height: 0; }

.nav-main > .nav-item {
  width: 15.83333%; }
  .nav-main > .nav-item.open > .nav-link {
    background-color: #C80130; }
  .nav-main > .nav-item.active > .nav-link .caret {
    fill: #C80130; }
  .nav-main > .nav-item > .nav-link {
    border-radius: 4px;
    background-color: #33439D;
    color: #fff; }
    .nav-main > .nav-item > .nav-link:hover {
      background-color: #C80130; }
    .nav-main > .nav-item > .nav-link .caret {
      fill: #fff; }

.nav-main .nav-item.active .nav-link {
  background: transparent !important;
  color: #C80130; }

.nav-main .nav-link {
  padding: 12px 10px;
  font-weight: 600;
  white-space: nowrap; }

.nav-main .dropdown-nav {
  min-width: 340px; }

.nav-list {
  border-radius: 4px;
  border: 3px solid #C80130;
  background-color: #fff;
  padding: 20px; }
  .nav-list .nav-item {
    text-align: left;
    white-space: nowrap; }
    .nav-list .nav-item.active .caret.--push {
      width: 0;
      margin-right: 0; }
  .nav-list .nav-link {
    padding: 10px 5px; }
  .nav-list .nav-item:not(:last-child) {
    border-bottom: 1px solid #e5e5e5; }
  .nav-list .active .nav-link {
    color: #C80130; }
  .nav-list .nav-link:hover,
  .nav-list .nav-link:focus {
    color: #C80130; }
  .nav-list .caret.--push {
    fill: #C80130; }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 90%;
  max-width: 320px;
  height: 100%;
  padding: 25px 15px;
  border-right: 3px solid #fff;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #33439D;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  @media (min-width: 768px) {
    .nav_mobile {
      display: none !important; } }
  .nav_mobile h4 {
    margin-top: 0;
    font-size: 1.2em;
    font-weight: 800;
    text-transform: uppercase; }
  .nav_mobile .nav {
    margin-bottom: 25px; }
  .nav_mobile .nav > .nav-item:not(:last-child) {
    border-bottom: 1px solid black; }
  .nav_mobile .nav > .nav-item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav > .nav-item.active .nav-link {
    background-color: #fff;
    color: #33439D; }
    .nav_mobile .nav > .nav-item.active .nav-link:hover, .nav_mobile .nav > .nav-item.active .nav-link:focus {
      outline: none;
      background-color: #fff;
      color: #33439D; }
  .nav_mobile .nav > .nav-item.open > .nav-link {
    background: #C80130 !important; }
  .nav_mobile .nav > .nav-item.open .dropdown-nav {
    display: block; }
  .nav_mobile .nav > .nav-item > .nav-link {
    padding: 12px;
    font-weight: 600;
    background-color: transparent;
    color: #fff; }
    .nav_mobile .nav > .nav-item > .nav-link:hover {
      outline: none;
      background-color: #C80130;
      color: #fff; }
    .nav_mobile .nav > .nav-item > .nav-link .caret {
      fill: #fff; }
  .nav_mobile .dropdown-nav {
    display: none;
    min-width: 0;
    border-radius: 0;
    border: none;
    padding: 0; }
    .nav_mobile .dropdown-nav .nav-item {
      white-space: normal; }
    .nav_mobile .dropdown-nav .nav-item:not(:last-child) {
      border-bottom: 1px dotted #bbb; }
    .nav_mobile .dropdown-nav .nav-item.active .nav-link {
      color: #C80130;
      font-weight: 800; }
      .nav_mobile .dropdown-nav .nav-item.active .nav-link .caret-push {
        fill: #33439D; }
    .nav_mobile .dropdown-nav .nav-link {
      padding: 12px;
      font-weight: 600;
      color: #33439D; }
      .nav_mobile .dropdown-nav .nav-link:hover {
        color: #C80130; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

.projects-wrapper .project-item:nth-child(even) {
  background-color: #fff;
  border-radius: 6px;
  border: 2px solid #ccc; }

.project-item {
  padding: 40px 20px 25px; }
  .project-item > *:first-child {
    margin-top: 0; }
  .project-item > *:last-child {
    margin-bottom: 0; }
  .project-item .gallery {
    line-height: 0; }

.review p {
  line-height: 1.35em; }

.review cite {
  display: block;
  margin-top: 5px; }

.review .icon-review {
  display: inline-block;
  height: 25px;
  width: 125px;
  margin-bottom: 10px;
  fill: #ff9900; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slideshow {
  line-height: 0px; }

.slide-caption {
  line-height: 1em; }

.slider_jumbo .slide-caption {
  position: relative;
  padding: 10px 10px 12px;
  border-top: 3px solid #f5f5f5;
  background: rgba(0, 0, 0, 0.85); }
  .slider_jumbo .slide-caption a {
    color: #fff;
    text-decoration: none; }
    .slider_jumbo .slide-caption a:hover {
      color: #fe3161; }
      .slider_jumbo .slide-caption a:hover .caption-icon {
        fill: #fe3161; }
  .slider_jumbo .slide-caption .caption-icon {
    width: 1rem;
    height: 1rem;
    transform: rotate(-90deg);
    fill: #fff; }

.slider_jumbo .slide-placeholder {
  width: 100%; }

@media (min-width: 768px) {
  .slider_jumbo .slide-placeholder {
    opacity: 0; }
  .slider_jumbo .slide {
    background-size: contain;
    background-repeat: no-repeat; }
  .slider_jumbo .slide-caption {
    padding: 10px 20px 12px;
    font-size: 18px;
    font-size: 1.125rem; }
  .slider_jumbo .slide_1 {
    background-image: url("/_/images/slides/jumbo/1-lg.webp"); }
  .slider_jumbo .slide_2 {
    background-image: url("/_/images/slides/jumbo/2-lg.webp"); }
  .slider_jumbo .slide_3 {
    background-image: url("/_/images/slides/jumbo/3-lg.webp"); }
  .slider_jumbo .slide_4 {
    background-image: url("/_/images/slides/jumbo/4-lg.webp"); }
  .slider_jumbo .slide_5 {
    background-image: url("/_/images/slides/jumbo/5-lg.webp"); }
  .slider_jumbo .slide_6 {
    background-image: url("/_/images/slides/jumbo/6-lg.webp"); } }

@media (min-width: 768px) {
  .no-webp .slider_jumbo .slide_1 {
    background-image: url("/_/images/slides/jumbo/1-lg.jpg"); }
  .no-webp .slider_jumbo .slide_2 {
    background-image: url("/_/images/slides/jumbo/2-lg.jpg"); }
  .no-webp .slider_jumbo .slide_3 {
    background-image: url("/_/images/slides/jumbo/3-lg.jpg"); }
  .no-webp .slider_jumbo .slide_4 {
    background-image: url("/_/images/slides/jumbo/4-lg.jpg"); }
  .no-webp .slider_jumbo .slide_5 {
    background-image: url("/_/images/slides/jumbo/5-lg.jpg"); }
  .no-webp .slider_jumbo .slide_6 {
    background-image: url("/_/images/slides/jumbo/6-lg.jpg"); } }

.slider_reviews {
  padding: 0 36px; }
  .slider_reviews .slick-prev,
  .slider_reviews .slick-next {
    z-index: 999999;
    height: 100%;
    width: 23px;
    background-color: transparent;
    background-image: url("/_/images/icons/slide-np-text.png") !important;
    background-size: 40px 20px;
    opacity: 0.3; }
    .slider_reviews .slick-prev:hover,
    .slider_reviews .slick-next:hover {
      opacity: 0.7; }
  .slider_reviews .slick-prev {
    left: 2px;
    background-position: 0 center; }
  .slider_reviews .slick-next {
    right: 0;
    background-position: -20px center; }

.youtube-player img {
  width: 100%; }

.video_wrapper {
  position: relative;
  background-color: #000; }
  .video_wrapper:hover {
    cursor: pointer; }
  .video_wrapper .play {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 300ms;
    background-color: rgba(0, 0, 0, 0.25); }
    .video_wrapper .play:hover {
      transition: all 300ms;
      background-color: transparent; }
    .video_wrapper .play .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 64px;
      height: 64px;
      fill: #f00; }

.widget {
  margin-bottom: 30px;
  padding: 15px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.275em;
  border-radius: 6px;
  border: 2px solid #ccc;
  background-color: #fff; }
  .widget.widget-primary {
    border-color: #33439D; }
    .widget.widget-primary .widget-title {
      color: #33439D; }
    .widget.widget-primary a {
      color: #33439D !important; }
  .widget.widget-secondary {
    border-color: #C80130; }
    .widget.widget-secondary .widget-title {
      color: #C80130; }
    .widget.widget-secondary a {
      color: #C80130 !important; }
  .widget.widget-highlight {
    border-color: #ff6600; }
    .widget.widget-highlight .widget-title {
      color: #ff6600; }
    .widget.widget-highlight a {
      color: #ff6600 !important; }
  .widget.widget-accent {
    border-color: #4d7ea8; }
    .widget.widget-accent .widget-title {
      color: #4d7ea8; }
    .widget.widget-accent a {
      color: #4d7ea8 !important; }
  .widget > *:first-child {
    margin-top: 0; }
  .widget > *:last-child {
    margin-bottom: 0; }

.widget-title {
  margin: 15px 0 10px;
  font-weight: 800;
  letter-spacing: -0.0375em; }

.widget_plain {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent; }

.widget-rates {
  max-width: 300px;
  margin: 0 auto 30px;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: -0.0325em; }
  .widget-rates p {
    display: flex;
    flex-wrap: nowrap;
    align-items: center; }
    .widget-rates p b {
      display: inline-block;
      padding: 5px 10px;
      margin-right: 10px;
      font-size: 190%;
      font-weight: 400;
      line-height: 1em;
      background-color: #33439D;
      color: #fff; }
    .widget-rates p em {
      font-style: normal; }
  @media (min-width: 992px) {
    .widget-rates {
      max-width: none; } }

.widget-usa {
  max-width: 360px;
  margin: 0 auto 30px;
  position: relative; }
  .widget-usa img {
    border-radius: 6px; }
  .widget-usa p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 15px 10px;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 1em;
    letter-spacing: -.0325em;
    color: #031d60; }
    .widget-usa p b {
      font-weight: 800;
      color: #db0007; }

.widget_menu {
  max-width: 360px;
  margin: 0 auto 30px; }
  .widget_menu .nav-link {
    font-weight: 600; }
  @media (min-width: 992px) {
    .widget_menu {
      max-width: none; } }

.widget_review {
  padding: 20px;
  border: 2px solid #ff9900;
  box-shadow: none; }
  .widget_review .review cite {
    vertical-align: middle; }
    .widget_review .review cite img {
      display: block;
      margin: 0 auto 10px;
      vertical-align: middle; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .widget_review .review cite img {
      display: inline-block;
      margin: 0 7px 0 0; } }

.block {
  padding: 30px; }
  @media (max-width: 991.98px) {
    .block .wrap + .wrap {
      margin-top: 15px; } }

.wrap > *:first-child {
  margin-top: 0; }

.wrap > *:last-child {
  margin-bottom: 0; }

.block_title {
  margin-bottom: 15px; }
  .block_title .block-title {
    margin: 0; }

.block-title {
  margin: 0 0 15px;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: -0.0525em; }
  @media (min-width: 768px) {
    .block-title {
      font-size: 32px;
      font-size: 2rem; } }

.block.corona {
  padding: 15px;
  border-top: 3px solid #222;
  background-color: #FFC107; }
  @media (min-width: 768px) {
    .block.corona .flex_wrap {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center; }
      .block.corona .flex_wrap > * {
        margin: 0 !important;
        display: inline-block;
        width: auto; }
      .block.corona .flex_wrap > *:first-child {
        padding-right: 30px; }
      .block.corona .flex_wrap .btn {
        white-space: nowrap; } }
  @media (min-width: 992px) {
    .block.corona .row {
      justify-content: center; }
    .block.corona .wrap {
      flex: 0 0 87.5%;
      max-width: 87.5%; } }
  @media (min-width: 1200px) {
    .block.corona .wrap {
      flex: 0 0 75%;
      max-width: 75%; } }

.block_banner {
  text-align: center;
  border-top: 10px solid #33439D;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dddddd' viewBox='0 0 360 120'%3E%3Cpath d='M358.7,114.1v-1.9h-3.6v-1.6h-1.5v-0.7h-3v0.7h-1v3.5h-1.5v-1.6h-0.3v-1h-4.5v1h-2.1v1.6h-1.6v-5.9h-2.1v4h-0.9 v-1h-3.3v1h-2.8v-1.7h-3.8v1.7h-3.4v-1.7h-5.5v2h-3.6v1.6h-3.2V113h-1.8v-4.4h-2.9v4.4h-2.1v-1.9h-2.8v1.9h-2.6v-1.8h-0.6v-1h-2.6v1 h-4.2v-2.7h-4.8V85.4h-1.5v-2.6h-10.6v2.6h-1.5v26.1h-0.9v2.7h-0.6v-50h-10.6v48.6h-0.7v-1.1h-1.2V81.1h-12.7v30.6h-1.8v1.5H243v-2 h-4.8v1.8H236v-1.1h-3.5v-1.1h-2.4v2.2h-1.8v-0.2h-1.7v0.2h-1v1.1h-2.1v-5.6h-4.7V76.3h-0.6v-4.9h-0.7v-5h-1.5v-2.9h-0.7v-1.5h-0.7 v-0.7l-0.8-0.7h-4.3l-0.8,0.7v0.7h-0.7v1.5h-0.7v2.9h-1.5v5h-0.7v4.9h-0.6v30h-1.2v1.5h-1.7V51.3h-13v59.1h-1.9v-44h-14v41.5h-2.4 v2.5H169v-3.9h-2.9V66.4h-14v24.1h-0.7V89H145v-2.5h-7.3V89h-0.9V65.7h-1.4V53.6H134v-5.8h-0.8V40H125v7.8h-0.8v5.8h-1.4v12.1h-1.4 v43.2h-0.7v-1.4h-1.5V82.1H115v-4.9h-10.3v4.9h-4.1v32h-1v-5.4h-4.1v2.2h-2.1v-3.2h-2.7V77h-9.1v34h-2.2v3.2H78v-5h-2.4V95.6v-25 h-21v25H49v13.6h-3.5v5h-2V113h-3.6v1.1h-2.8v-4.3h-4.7v3h-4.1v-4.3h-5.9V87h-2.2v-1.4h-4.3V87h-2.2v25.1h-2.2v2.1H0v5.9h360v-5.9 H358.7z'/%3E%3C/svg%3E"), linear-gradient(to top, #ddd 13px, transparent 13px);
  background-position: center bottom;
  background-repeat: no-repeat; }
  @media (min-width: 768px) {
    .block_banner .row {
      align-items: center; }
    .block_banner .banner_branding {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0;
      text-align: left; }
    .block_banner .banner_contact {
      flex: 0 0 75%;
      max-width: 75%;
      margin-top: 0 !important;
      text-align: right; }
    .block_banner .banner_nav {
      margin-top: 30px; } }
  @media (min-width: 992px) {
    .block_banner .row {
      align-items: flex-start; }
    .block_banner .banner_contact {
      margin-top: 30px !important; }
    .block_banner .banner_nav {
      flex: 0 0 68.75%;
      max-width: 68.75%;
      margin: -80px 0 0 auto; } }
  @media (min-width: 1200px) {
    .block_banner .banner_nav {
      flex: 0 0 62.5%;
      max-width: 62.5%; } }

.banner_button {
  text-align: left; }

.branding-title {
  margin: 0;
  font-weight: normal;
  line-height: 0; }

.branding-logo {
  display: inline-block;
  width: 100%;
  max-width: 240px;
  margin-top: -40px; }
  @media (min-width: 768px) {
    .branding-logo {
      margin-top: 0; } }

.banner_contact strong {
  display: block;
  margin-bottom: 3px; }

@media (min-width: 768px) {
  .banner_contact {
    display: flex;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center; }
    .banner_contact > div {
      margin: 0 0 0 30px; } }

.rating-wrapper {
  margin-bottom: 10px; }

.rating-item {
  display: inline-block;
  line-height: 0;
  transform: scale(0.9);
  transition: all 300ms; }
  .rating-item:hover {
    transform: scale(1);
    transition: all 300ms; }
  .rating-item:not(:last-child) {
    margin-right: 10px; }

.rating-logo {
  width: 30px;
  height: 30px;
  margin-right: 5px; }
  @media (min-width: 768px) {
    .rating-logo {
      width: 24px;
      height: 24px; } }

.rating-stars {
  width: 81.25px;
  height: 15px;
  fill: #ff9900; }
  @media (min-width: 768px) {
    .rating-stars {
      width: 97.5px;
      height: 18px; } }

.rating-title {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1em; }

.contact-title {
  font-weight: 600;
  line-height: 1em;
  letter-spacing: -0.0425em;
  color: #C80130; }

.contact-phone {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.0475em; }

.banner_nav {
  margin-top: 30px;
  padding: 0; }

.block_announce {
  padding: 15px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 800;
  letter-spacing: -0.0425em;
  line-height: 1em;
  text-align: center;
  border-bottom: 4px solid #f5f5f5;
  background-color: #C80130;
  color: #fff; }
  @media (min-width: 768px) {
    .block_announce ul.list {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      align-items: center; }
      .block_announce ul.list li {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        margin: 0; } }

body.page_front .block_announce {
  border-bottom: none; }

.page_title {
  padding: 45px 0 0;
  border-bottom: 5px solid #fff;
  color: #fff;
  /* background-color: $color-primary;
 	background: linear-gradient(
 		to right,
 		theme(primary, dark),
 		theme(primary, base) 40%,
 		theme(primary, base) 60%,
 		theme(primary, dark)
	); */
  background-attachment: fixed;
  background-image: linear-gradient(120deg, transparent 50%, rgba(255, 255, 255, 0.1) 50%, transparent), linear-gradient(to bottom, #33439D, #3c4fba); }
  .page_title .page_title_wrapper {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4); }
  @media (min-width: 768px) {
    .page_title {
      padding-top: 90px; } }
  @media (min-width: 992px) {
    .page_title {
      padding-top: 150px; } }

.page-title {
  margin: 0; }
  .page-title small {
    font-size: 65%;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 1em;
    color: #fff; }

@media (min-width: 992px) {
  .block_content .main {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    padding-right: 20px; }
  .block_content .sidebar {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    padding-left: 20px; } }

@media (min-width: 1200px) {
  .block_content .main {
    flex: 0 0 68.75%;
    max-width: 68.75%;
    padding-right: 20px; }
  .block_content .sidebar {
    flex: 0 0 31.25%;
    max-width: 31.25%;
    padding-left: 20px; } }

@media (min-width: 992px) {
  .block_content.full_width .main {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .block_content.flip .main {
    order: 2; }
  .block_content.flip .sidebar {
    order: 1; } }

@media (min-width: 992px) {
  .resource_careers .block_content .main {
    flex: 0 0 43.75%;
    max-width: 43.75%;
    padding-right: 15px; }
  .resource_careers .block_content .sidebar {
    flex: 0 0 56.25%;
    max-width: 56.25%;
    padding-left: 15px; } }

.block_content {
  background-color: rgba(255, 255, 255, 0.25); }

.page_front .block_main.content {
  background-color: transparent; }

.main > *:last-child {
  margin-bottom: 0; }

.main-article {
  padding: 20px;
  background-color: white;
  border-radius: 6px;
  border: 2px solid #ccc; }
  .main-article > *:first-child {
    margin-top: 0; }
  .main-article > *:last-child {
    margin-bottom: 0; }
  .main-article .article-gallery {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 15px; }
    .main-article .article-gallery > img {
      width: 49%; }

.ohd {
  display: block;
  float: right;
  margin: 0 0 15px 15px;
  border: 1px solid #ccc;
  background-color: #fff; }
  .ohd a {
    display: block;
    padding: 10px 30px;
    font-weight: 800;
    color: #C80130; }
  .ohd img {
    max-width: 150px; }

.main-widget-wrapper {
  margin-bottom: 15px; }
  .main-widget-wrapper > * {
    margin: 0; }
  @media (min-width: 768px) {
    .main-widget-wrapper {
      float: right;
      margin: 0 0 15px 15px; } }

.sidebar .form:not(.form_jobs) {
  margin-bottom: 30px;
  border-radius: 6px;
  border: 2px solid #ddd; }

@media (max-width: 991.98px) {
  .sidebar {
    padding-top: 30px; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .sidebar .form:not(.form_jobs) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .sidebar .form:not(.form_jobs) .form-title {
      width: 100%; }
    .sidebar .form:not(.form_jobs) .form_wrap_info {
      width: 49%; }
    .sidebar .form:not(.form_jobs) .form_wrap_comments {
      width: 49%; }
    .sidebar .form:not(.form_jobs) .form_wrap_submit {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      flex-wrap: nowrap;
      margin-top: 10px; }
      .sidebar .form:not(.form_jobs) .form_wrap_submit .form_submit {
        margin-left: 270px; } }

.block_coverage {
  text-align: center;
  border-top: 5px solid #ddd;
  background-color: #fff; }
  .block_coverage .row {
    justify-content: center; }
  @media (min-width: 992px) {
    .block_coverage .wrap {
      flex: 0 0 75%;
      max-width: 75%; } }
  @media (min-width: 1200px) {
    .block_coverage .wrap {
      flex: 0 0 68.75%;
      max-width: 68.75%; } }

.site_info {
  background-color: #2a3780;
  color: #fff; }
  .site_info a {
    color: #fff; }
  .site_info .phone {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 800;
    color: #fff; }
  .site_info .list > li:not(:last-child) {
    margin-bottom: 4px; }
  @media (min-width: 576px) {
    .site_info .row {
      align-items: center; }
    .site_info .wrap {
      flex: 0 0 50%;
      max-width: 50%; }
    .site_info .info_copyright {
      text-align: right; }
    .site_info .list {
      margin-bottom: 0; } }

.front_jumbo {
  background-image: repeating-conic-gradient(from 0deg, transparent 0deg 15deg, #e5e5e5 15deg 30deg); }
  .front_jumbo .wrap {
    padding: 0; }
  @media (min-width: 992px) {
    .front_jumbo .jumbo_list {
      padding-top: 15px; } }

/* .jumbo_conversion {
	@include media-breakpoint-up(lg) {
		position: relative;
		.conversion_text {
			position: absolute;
			z-index: 2;
			bottom: 75px;
			padding: 10px 20px;
			width: 100%;
			margin-bottom: 0;
			background-color: rgba($white, 0.8);
		}
	}
} */
.conversion_text {
  margin-bottom: 30px;
  text-align: center; }
  .conversion_text p {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .conversion_text {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      align-items: center; }
      .conversion_text .conversion-text {
        text-align: left;
        width: 100%; }
      .conversion_text .conversion-button {
        margin-top: 0;
        padding-left: 30px;
        width: auto;
        white-space: nowrap; } }
  @media (min-width: 992px) {
    .conversion_text {
      justify-content: center; }
      .conversion_text .conversion-text {
        width: auto; } }

.conversion-text p {
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: -0.0325em; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .conversion-text p {
      font-size: 15px;
      font-size: 0.9375rem; } }
  @media (min-width: 992px) {
    .conversion-text p {
      font-weight: 800;
      letter-spacing: -0.0475em; } }

.conversion-button {
  margin-top: 10px;
  line-height: 1em; }

.front_choose {
  background-color: #e5e5e5; }
  .front_choose .wrap {
    padding: 0; }
  @media (min-width: 768px) {
    .front_choose .row {
      align-items: center;
      justify-content: center; }
    .front_choose .choose_list {
      flex: 0 0 68.75%;
      max-width: 68.75%; }
    .front_choose .choose_media {
      flex: 0 0 31.25%;
      max-width: 31.25%; } }
  .front_choose .wrap {
    padding: 0; }
  @media (min-width: 992px) {
    .front_choose .choose_media {
      flex: 0 0 18.75%;
      max-width: 18.75%; } }
  @media (min-width: 1200px) {
    .front_choose .choose_list {
      flex: 0 0 62.5%;
      max-width: 62.5%; } }

.choose_media {
  line-height: 0;
  margin-top: 0 !important;
  text-align: right; }
  .choose_media .choose-media {
    margin-top: -150px; }
  .choose_media .img-satisfaction {
    width: 132px;
    height: 116.05px; }
  @media (min-width: 576px) {
    .choose_media .choose-media {
      margin-top: -185px; }
    .choose_media .img-satisfaction {
      width: 192px;
      height: 168.8px; } }
  @media (min-width: 768px) {
    .choose_media .choose-media {
      margin-top: 0; } }

.choose-list {
  line-height: 1em;
  font-weight: 600;
  color: #C80130; }
  .choose-list li {
    padding: 5px 5px 5px 35px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' fill='%23C80130'%3E%3Cpath d='M17,42.9L0.7,26.7c-1-1-1-2.6,0-3.5l3.5-3.5c1-1,2.6-1,3.5,0l10.9,10.9L42.2,7.1c1-1,2.6-1,3.5,0l3.5,3.5c1,1,1,2.6,0,3.5L20.5,42.9C19.5,43.9,18,43.9,17,42.9L17,42.9z'/%3E%3C/svg%3E") left 5px no-repeat;
    background-size: auto 25px; }
  .choose-list strong {
    display: block;
    color: #33439D;
    font-weight: 800; }
  .choose-list small {
    font-size: inherit; }
  @media (min-width: 992px) {
    .choose-list {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start; }
      .choose-list li {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 35px; }
        .choose-list li:nth-child(3) {
          margin-bottom: 0 !important; } }
  @media (min-width: 1200px) {
    .choose-list li:nth-child(odd) {
      flex: 0 0 56.25%;
      max-width: 56.25%; }
    .choose-list li:nth-child(even) {
      flex: 0 0 43.75%;
      max-width: 43.75%; } }

.hidden {
  display: none !important; }

@media (max-width: 575.98px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none !important; }

@media (max-width: 575.98px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt30 {
  padding-top: 30px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.no-transform {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ff--serif {
  font-family: "Times New Roman", Georgia, Times, serif !important; }

.ff--sans {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 800 !important; }

.hw0 {
  font-weight: 400 !important; }

.hw1 {
  font-weight: 600 !important; }

.hw2 {
  font-weight: 800 !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.color--text {
  color: #444 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #33439D !important; }

.color--secondary {
  color: #C80130 !important; }

.color--highlight {
  color: #ff6600 !important; }

.color--accent {
  color: #4d7ea8 !important; }

.color--link {
  color: #33439D !important; }

.color--review {
  color: #ff9900 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #cc2936 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #87bcde !important; }

.bg--text {
  background-color: #444 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #33439D !important; }

.bg--secondary {
  background-color: #C80130 !important; }

.bg--highlight {
  background-color: #ff6600 !important; }

.bg--link {
  background-color: #33439D !important; }

.bg--review {
  background-color: #ff9900 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #cc2936 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #87bcde !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #f5f5f5 !important; }

.gdfancybg--text {
  background: #444;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #444; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #fff; }

.gdfancybg--primary {
  background: #33439D;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #33439D; }

.gdfancybg--secondary {
  background: #C80130;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #C80130; }

.gdfancybg--highlight {
  background: #ff6600;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #ff6600; }

.gdfancybg--review {
  background: #ff9900;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #ff9900; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #28a745; }

.gdfancybg--danger {
  background: #cc2936;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #cc2936; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--info {
  background: #87bcde;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #87bcde; }
