.form_quick {

	padding: 20px;
	background-color: $white;

	.page_contact & {
		background-color: transparent;
		padding: 0;
	}

}