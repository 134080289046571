body {

	min-width: 360px;
	background-color: $body-bg;

	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {
			padding-bottom: 145px; height: 100%; 
			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}
		}

	}

}