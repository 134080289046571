.youtube-player img { width: 100%; }

.video_wrapper {
	position: relative;
	background-color: $black;

	&:hover {
		cursor: pointer;
	}

	.play {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		transition: all 300ms;
		background-color: rgba(black, 0.25);
		&:hover {
			transition: all 300ms;
			background-color: transparent;
		}
		.icon {
			@include align(true, true, absolute);
			width: 64px;
			height: 64px;
			fill: #f00;
		}
	}

}