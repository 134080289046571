.block_coverage {
	text-align: center;
	border-top: 5px solid #ddd;
	//border-bottom: 5px solid #ddd;
	background-color: $white;

	.row { justify-content: center; }

	//@include media-breakpoint-up(md) { .wrap {@include make-col(13); } }
	@include media-breakpoint-up(lg) { .wrap {@include make-col(12); } }
	@include media-breakpoint-up(xl) { .wrap {@include make-col(11); } }

}
