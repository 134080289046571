.widget_menu {
	max-width: 360px;
	margin: 0 auto 30px;

	.nav-link {
		font-weight: $body-weight-bold;
	}

	@include media-breakpoint-up(lg) {
		max-width: none;
	}

}