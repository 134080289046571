.nav-list {

	border-radius: 4px;
	border: 3px solid $color-secondary;
	background-color: $white;
	padding: 20px;

	.nav-item {
		text-align: left;
		white-space: nowrap;
		&.active {
			.caret.--push {
				width: 0;
				margin-right: 0;
			}
		}
	}

	.nav-link {
		padding: 10px 5px;
	}

	.nav-item:not(:last-child) {
		border-bottom: 1px solid #e5e5e5;
	}

	.active .nav-link { color: $color-secondary; }

	.nav-link:hover,
	.nav-link:focus { color: $color-secondary; }
	
	.caret.--push { fill: $color-secondary; }

}