.review {

	p {
		line-height: 1.35em;
	}

	cite {
		display: block;
		margin-top: 5px
	}

	.icon-review {
		display: inline-block;
		height: 25px;
		width: 125px;
		margin-bottom: 10px;
		fill: $color-review;
	}

}