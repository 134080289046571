.sidebar {

	.form:not(.form_jobs) {
		margin-bottom: 30px;
		border-radius: 6px;
		border: 2px solid #ddd;
	}

	@include media-breakpoint-down(md) {
		padding-top: 30px;
	}

	@include media-breakpoint-only(md) {

		.form:not(.form_jobs) {
			@include make-flex;
			justify-content: space-between;
			.form-title { width: 100%; }
			.form_wrap_info { width: 49%; }
			.form_wrap_comments { width: 49%; }
			.form_wrap_submit {
				@include make-flex;
				width: 100%;
				flex-wrap: nowrap;
				margin-top: 10px;
				.form_submit { margin-left: 270px; }
			}
		}

	}

}