

// FROM BOOTSTRAP FUNCTIONS

@mixin _assert-ascending($map, $map-name) {
	$prev-key: null;
	$prev-num: null;
	@each $key,
	$num in $map {
		@if $prev-num==null or unit($num)=="%"or unit($prev-num)=="%" {
			// Do nothing
		}
		@else if not comparable($prev-num, $num) {
			@warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
		}
		@else if $prev-num>=$num {
			@warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
		}
		$prev-key: $key;
		$prev-num: $num;
	}
}

@mixin _assert-starts-at-zero($map, $map-name: "$grid-breakpoints") {
	@if length($map)>0 {
		$values: map-values($map);
		$first-value: nth($values, 1);

		@if $first-value !=0 {
			@warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
		}
	}
}


// FROM BOOTSTRAP VARIABLES

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$grid-row-columns: 6 !default;



// FROM BOOTSTRAP MIXINS/BREAKPOINTS

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n !=null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}


@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min !=0, $min, null);
}


@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}


@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
	@return if(breakpoint-min($name, $breakpoints)==null, "", "-#{$name}");
}


@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	}
	@else {
		@content;
	}
}


@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	}
	@else {
		@content;
	}
}


@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);
	@if $min !=null and $max !=null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	}
	@else if $max==null {
		@include media-breakpoint-up($lower, $breakpoints) {
			@content;
		}
	}
	@else if $min==null {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);
	@if $min !=null and $max !=null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	}
	@else if $max==null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	}
	@else if $min==null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}


// FROM BOOTSTRAP MIXINS/GRID

@mixin make-container($gutter: $grid-gutter-width) {
	width: 100%;
	padding-right: $gutter / 2;
	padding-left: $gutter / 2;
	margin-right: auto;
	margin-left: auto;
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {

	@each $breakpoint,
	$container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width;
		}
	}
}

@mixin make-row($gutter: $grid-gutter-width) {
	display: flex;
	flex-wrap: wrap;
	margin-right: -$gutter / 2;
	margin-left: -$gutter / 2;
}

  @mixin make-flex() {
	display: flex;
	flex-wrap: wrap;    
  }

@mixin make-col-ready($gutter: $grid-gutter-width) {
	position: relative;
	width: 100%;
	padding-right: $gutter / 2;
	padding-left: $gutter / 2;
}

@mixin make-col($size, $columns: $grid-columns) {
	flex: 0 0 percentage($size / $columns);
	max-width: percentage($size / $columns);
}

@mixin make-col-auto() {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%; // Reset earlier grid tiers
}

@mixin make-col-offset($size, $columns: $grid-columns) {
	$num: $size / $columns;
	margin-left: if($num==0, 0, percentage($num));
}

@mixin row-cols($count) {
	& > * {
		flex: 0 0 100% / $count;
		max-width: 100% / $count;
	}
}

.container {
	@include make-container();
	@include make-container-max-widths();
}

.container-fluid {
	width: 100%;
	@include make-container();

	@include media-breakpoint-up(xl) {
		max-width: 1140px;
		margin: 0 auto;
	}
}

.row {
	@include make-row();
}

.wrap {
	@include make-col-ready();
}