.--has-icon {
	vertical-align: middle;
	> * { vertical-align: middle; }
}

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	line-height: 0;
	vertical-align: middle;
}

/* .icon-12 { width: 12px; height: 12px; }
.icon-16 { width: 16px; height: 16px; }

.icon-18 { width: 18px; height: 18px; }
.icon-64 { width: 64px; height: 64px; }*/

.icon-primary { fill: $color-primary; }
.icon-secondary { fill: $color-secondary; }
.icon-star { fill: $color-review; }
.icon-white { fill: $white; }