// ===========================================
// MATH FUNCTIONS
// ===========================================
@function pow($number, $exponent) {
	$value: 1;
	@if $exponent > 0 {
		@for $i from 1 through $exponent {
			$value: $value * $number;
		}
	}
	@return $value;
}

@function calculateRem($size, $base) {
	$remSize: $size / $base;
	@return $remSize * 1rem;
}

// ===========================================
// COLOR FUNCTIONS
// ===========================================
@function theme($color-name, $color-variant:null) {
	// color variant is optional
	@if ($color-variant != null) {
		// map inception
		@return map-get(map-get($theme, $color-name), $color-variant);
	} @else {
		@return map-get($theme, $color-name);
	}
}