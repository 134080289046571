@mixin limbo {
	li { margin-bottom: 0 !important; }
}

%list, .list {
	margin: 0 0 15px;
	padding: 0 0 0 18px;
	li:not(:last-child) { margin-bottom: 8px; }
}

	li > ul { margin-top: 8px !important; }

		@mixin list {
			margin: 0 0 15px;
			padding: 0 0 0 18px;	
			li:not(:last-child) { margin-bottom: 8px; }		
		}

%list_unstyled, .list-unstyled {
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}
		
		@mixin list_unstyled {
			margin-left: 0;
			padding-left: 0;
			list-style-type: none;
			list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);		
		}

%list_inline, .list-inline {
	@extend %list_unstyled;
	li { display: inline-block; }
}

		@mixin list_inline {
			@include list_unstyled;
			li { display: inline-block; }
		}

.list-wrap {
	margin-bottom: 15px;
	ul { margin: 0; }
	ul+ul { margin-top: 5px; }
	@include media-breakpoint-up(md) {
		@include make-flex;
		flex-wrap: nowrap;
		ul {
			line-height: 1.25em;
			li:not(:last-child) { margin-bottom: 8px; }
		}
		ul+ul { margin-top: 0; padding-left: 40px; }
		&.l2 ul { width: percentage(1/2); }
		&.l3 ul { width: percentage(1/3); }
		&.l4 ul { width: percentage(1/4); }
	}
}