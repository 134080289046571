.front_choose {
	background-color: #e5e5e5;
	.wrap { padding: 0; }
	@include media-breakpoint-up(md) {
		.row { align-items: center; justify-content: center; }
		.choose_list { @include make-col(11); }
		.choose_media { @include make-col(5); }
	}
	.wrap { padding: 0; }
	@include media-breakpoint-up(lg) {
		.choose_media { @include make-col(3); }
	}
	@include media-breakpoint-up(xl) {
		.choose_list { @include make-col(10); }
	}
}

//.choose_list {}

.choose_media {
	line-height: 0;
	margin-top: 0 !important;
	text-align: right;
	.choose-media { margin-top: -150px; }
	.img-satisfaction {
		width: ((480 * 0.275) * 1px);
		height: ((422 * 0.275) * 1px);
	}
	@include media-breakpoint-up(sm) {
		.choose-media { margin-top: -185px; }
		.img-satisfaction {
			width: ((480 * 0.4) * 1px);
			height: ((422 * 0.4) * 1px);
		}
	}
	@include media-breakpoint-up(md) {
		.choose-media { margin-top: 0; }
	}
}

.choose-list {
	line-height: 1em;
	font-weight: $body-weight-bold;
	color: $color-secondary;
	li {
		padding: 5px 5px 5px 35px;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' fill='%23C80130'%3E%3Cpath d='M17,42.9L0.7,26.7c-1-1-1-2.6,0-3.5l3.5-3.5c1-1,2.6-1,3.5,0l10.9,10.9L42.2,7.1c1-1,2.6-1,3.5,0l3.5,3.5c1,1,1,2.6,0,3.5L20.5,42.9C19.5,43.9,18,43.9,17,42.9L17,42.9z'/%3E%3C/svg%3E") left 5px no-repeat;
		background-size: auto 25px;
	}
	strong {
		display: block;
		color: $color-primary;
		font-weight: $body-weight-bolder;
	}
	small { font-size: inherit; }
	@include media-breakpoint-up(lg) {
		@include make-flex;
		align-items: flex-start;
		li {
			@include make-col-ready();
			@include make-col(8);
			padding-left: 35px;
			&:nth-child(3) { margin-bottom: 0 !important; }
		}
	}

	@include media-breakpoint-up(xl) {
		li:nth-child(odd) { @include make-col(9); }
		li:nth-child(even) { @include make-col(7); }
	}
}