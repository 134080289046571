$nav-mobile-bg: 			$color-primary !default;
$mobile-link-bg: 			transparent !default;
$mobile-link-color:			$white !default;
$mobile-hover-bg: 			$color-secondary !default;
$mobile-hover-color: 		$white !default;
$mobile-active-bg: 			$white !default;
$mobile-active-color: 		theme(primary, base) !default;

@mixin offcanvas {
	display: none;
	&.in { display: block; }
}


.nav_mobile {

	@include offcanvas;
	position: fixed;
	z-index: 999999;
	top: 0; right: auto;
	bottom: 0; left: 0;
	overflow-y: auto;
	width: 90%;
	max-width: 320px;
	height: 100%;
	padding: 25px 15px;
	border-right: 3px solid $white;
	box-shadow: 2px 0 5px 0 rgba(0,0,0,0.5);
	background-color: $nav-mobile-bg;
	color: $white;

	@include media-breakpoint-up(md) { display: none !important; }

	h4 {
		margin-top: 0;
		font-size: 1.2em;
		font-weight: 800;
		text-transform: uppercase;
	}

	.nav { margin-bottom: 25px; }

	.nav > .nav-item:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,1); }
	.nav > .nav-item:not(:first-child) { border-top: 1px solid rgba(255,255,255,0.2); }

	.nav > .nav-item {

		&.active .nav-link {
			background-color: $mobile-active-bg;
			color: $mobile-active-color;
			&:hover, &:focus {
				outline: none;
				background-color: $mobile-active-bg;
				color: $mobile-active-color;
			}
		}

		&.open {
			> .nav-link {
				background: $mobile-hover-bg !important;
			}
			.dropdown-nav { display: block; }
		}

	}	

		.nav > .nav-item > .nav-link {
			padding: 12px;
			font-weight: 600;
			background-color: $mobile-link-bg;
			color: $mobile-link-color;
			&:hover {
				outline: none;
				background-color: $mobile-hover-bg;
				color: $mobile-hover-color;
			}
			.caret { fill: $white; }
		}

	.dropdown-nav {
		display: none;
		min-width: 0;
		border-radius: 0;
		border: none;
		padding: 0;

		.nav-item { white-space: normal; }

		.nav-item:not(:last-child) { border-bottom: 1px dotted #bbb; }

		.nav-item.active .nav-link {
			color: $color-secondary;
			font-weight: 800;
			.caret-push { fill: $color-primary; }
		}


		.nav-link {
			padding: 12px;
			font-weight: 600;
			color: $color-primary;
			&:hover { color: $color-secondary; }
		}

	}

	.mobile-toggle {

		font-size: 1.2em;
		color: #fff;
		text-decoration: none;

		img {
			display: inline-block;
			width: 0.8em;
			margin-right: 10px;
		}

	}

}

