.block_content {
	background-color: rgba($white,0.25);
}

.page_front .block_main.content {
	background-color: transparent;
}

.main {
	>*:last-child { margin-bottom: 0; }
}


.main-article {
	padding: 20px;
	background-color: white;
	border-radius: 6px;
	border: 2px solid #ccc;

	> *:first-child { margin-top: 0; }
	> *:last-child { margin-bottom: 0; }

	.article-gallery {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin-bottom: 15px;
		> img {
			width: 49%;
		}
	}

}

.ohd {
	display: block;
	float: right;
	margin: 0 0 15px 15px;
	border: 1px solid #ccc;
	background-color: $white;
	a {
		display: block;
		padding: 10px 30px;
		font-weight: $body-weight-bolder;
		color: $color-secondary;
	}
	img { max-width: 150px; }
}


.main-widget-wrapper {
	margin-bottom: 15px;
	> * { margin: 0; }

	@include media-breakpoint-up(md) {
		float: right;
		margin: 0 0 15px 15px;
	}

}