html {

	@include font-size(16);
	letter-spacing: -0.0275em;
	line-height: $base-line-height;
	-webkit-text-size-adjust: 100%;

	@include media-breakpoint-up(lg) { @include font-size(17); }

}



body { font-family: $font-family-body; }



%heading {
	margin: 30px 0 15px;
	font-weight: $heading-weight-normal;
	line-height: 1em;
	letter-spacing: -0.0425em;
	small {
		display: block;
		font-size: 75%;
		font-family: $font-family-sans-serif;
		font-weight: $body-weight-normal;
		letter-spacing: -0.0125em;
		line-height: 1em;
		color: #888;
	}
}

h5, .h5, %h5,
h6, .h6, %h6 {
	@extend %heading;
	@include font-size(16);
	@include media-breakpoint-up(lg) { @include font-size(17); }
}

h4, .h4, %h4 {
	@extend %heading;
	@include font-size(18);
	@include media-breakpoint-up(lg) { @include font-size(20); }
}


h3, .h3, %h3 {
	@extend %heading;
	@include font-size(20);
	font-family: $font-family-body;
	font-weight: $body-weight-bolder;
	letter-spacing: -0.0525em;
	small { font-weight: $body-weight-bold; }
	@include media-breakpoint-up(lg) { @include font-size(23); }
}



h2, .h2, %h2 {
	@extend %heading;
	@include font-size(22);
	@include media-breakpoint-up(lg) { @include font-size(27); }
}



h1, .h1, %h1 {
	@extend %heading;
	@include font-size(27);
	font-weight: $body-weight-bolder;
	letter-spacing: -0.0525em;
	small { font-weight: $body-weight-bold; }
	@include media-breakpoint-up(md) { @include font-size(36); }
}



p {
	margin: 0 0 $global_mb;
	&.lead {
		font-size: 118%;
		line-height: (1.5 * 1.02) * 1em;
	}
}



ul, ol {
	margin: 0;
	padding: 0;
}



b, strong { font-weight: $body-weight-bold; }



address {
	margin: 0 0 $global_mb;
	font-style: normal;
}



blockquote {

	margin: 0 0 1.5em;
	padding: 1em;
	font-size: 112.5%;
	line-height: 1.425em;
	border-left: 0.5rem solid;

	>* { margin-bottom: 0.5em }
	>*:last-child { margin-bottom: 0; }

	&.bq--text { border-color: $color-text; }
	&.bq--primary { border-color: theme(primary, base); }
	&.bq--secondary { border-color: theme(secondary, base); }
	&.bq--highlight { border-color: theme(highlight, base); }
	&.bq--accent { border-color: theme(accent, base); }
	&.bq--success { border-color: theme(success, base); }
	&.bq--danger { border-color: theme(danger, base); }
	&.bq--warning { border-color: theme(warning, dark); }
	&.bq--info { border-color: theme(info, base); }

	&.review {
		padding: 0;
		margin: 0 0 1em;
		font-size: 1rem;
		border: none;
	}

}

	.bq--title {
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0275em;
		text-transform: uppercase;
		.bq--text & { color: $color-text; }
		.bq--primary & { color: theme(primary, base); }
		.bq--secondary & { color: theme(secondary, base); }
		.bq--highlight & { color: theme(highlight, base); }
		.bq--accent & { color: theme(accent, base); }
		.bq--success & { color: theme(success, base); }
		.bq--danger & { color: theme(danger, base); }
		.bq--warning & { color: theme(warning, dark); }
		.bq--info & { color: theme(info, base); }	
	}

blockquote.bq-alt {
	margin-right: 5em;
	padding: 1.5em;
	border-radius: 6px;
	border: 4px solid $color-text;
	background-color: $white;
	&.bq--primary { border-color: $color-primary; }
	&.bq--secondary { border-color: $color-secondary; }
	&.bq--highlight { border-color: $color-highlight; }
	&.bq--accent { border-color: $color-accent; }
	&.bq--success { border-color: $color-success; }
	&.bq--danger { border-color: $color-danger; }
	&.bq--warning { border-color: $color-warning; }
	&.bq--info { border-color: $color-info; }
}


hr {
    display: block;
    clear: both;
    width: 100%;
    height: 1px;
    margin: 2em auto;
    border: none;
    background-color: #bbb;

	&.hr-inset {
	    margin: 2em auto;
	    border: none;
	    background-color: #bbb;
	    box-shadow: 0 1px 0 0 #fff;
	}
    
	.content_text & {
		margin: 1em 0 !important;
	}

}	