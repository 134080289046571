.slider_jumbo {
	
	.slide-caption {
		position: relative;
		padding: 10px 10px 12px;
		border-top: 3px solid $body-bg;
		background: rgba(black, 0.85);
		a { 
			color: $white;
			text-decoration: none;
			&:hover {
				color: theme(secondary, light);
				.caption-icon { fill: theme(secondary, light); }
			}
		}
		.caption-icon {
			width: 1rem; height: 1rem;
			transform: rotate(-90deg);
			fill: $white;
		}

	}

    .slide-placeholder { width: 100%; }

    @include media-breakpoint-up(md) {

		.slide-placeholder { opacity: 0; }
		
		.slide { background-size: contain; background-repeat: no-repeat; }

		.slide-caption {
			padding: 10px 20px 12px;
			@include font-size(18);
		}

        .slide_1 { background-image: url("/_/images/slides/jumbo/1-lg.webp"); }
        .slide_2 { background-image: url("/_/images/slides/jumbo/2-lg.webp"); }
        .slide_3 { background-image: url("/_/images/slides/jumbo/3-lg.webp"); }
        .slide_4 { background-image: url("/_/images/slides/jumbo/4-lg.webp"); }
        .slide_5 { background-image: url("/_/images/slides/jumbo/5-lg.webp"); }
        .slide_6 { background-image: url("/_/images/slides/jumbo/6-lg.webp"); }

    }

}

.no-webp .slider_jumbo {
    @include media-breakpoint-up(md) {
        .slide_1 { background-image: url("/_/images/slides/jumbo/1-lg.jpg"); }
        .slide_2 { background-image: url("/_/images/slides/jumbo/2-lg.jpg"); }
        .slide_3 { background-image: url("/_/images/slides/jumbo/3-lg.jpg"); }
        .slide_4 { background-image: url("/_/images/slides/jumbo/4-lg.jpg"); }
        .slide_5 { background-image: url("/_/images/slides/jumbo/5-lg.jpg"); }
        .slide_6 { background-image: url("/_/images/slides/jumbo/6-lg.jpg"); }		
	}
}