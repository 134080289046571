.block_announce {
	padding: 15px;
	@include font-size(18);
	font-weight: $body-weight-bolder;
	letter-spacing: -0.0425em;
	line-height: 1em;
	text-align: center;
	border-bottom: 4px solid $body-bg;
	background-color: $color-secondary;
	color: $white;
	@include media-breakpoint-up(md) {
		ul.list {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: center;
			li {
				@include make-col-ready;
				@include make-col(4,12);
				margin: 0;
			}
		}
	}
}

body.page_front .block_announce { border-bottom: none; }