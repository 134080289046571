$nav_count: 6;

.nav-main {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	line-height: 0;
}

	.nav-main > .nav-item {
		width: ((100 - ($nav_count - 1)) / $nav_count) * 1%;
		&.open > .nav-link { background-color: $color-secondary; }

		&.active > .nav-link {
			.caret { fill: $color-secondary; }
		}

		//&.active > .nav-link { background-color: rgba($white,0.4); }

		> .nav-link {
			border-radius: 4px;
			background-color: $color-primary;
			color: $white;
			&:hover { background-color: $color-secondary; }
			.caret { fill: $white; }
		}
		
	}

	.nav-main .nav-item.active .nav-link {
		background: transparent !important;
		color: $color-secondary;
	}
	
	.nav-main .nav-link {
		padding: 12px 10px;
		font-weight: $body-weight-bold;
		white-space: nowrap;
	}

	.nav-main .dropdown-nav {
		min-width: 340px;
	}

