	.block_content {

		@include media-breakpoint-up(lg) {
			.main {
				@include make-col(10);
				padding-right: 20px;
			}

			.sidebar {
				@include make-col(6);
				padding-left: 20px;
			}
		}

		@include media-breakpoint-up(xl) {
			.main {
				@include make-col(11);
				padding-right: 20px;
			}

			.sidebar {
				@include make-col(5);
				padding-left: 20px;
			}
		}

		&.full_width {
			@include media-breakpoint-up(lg) {
				.main {
					@include make-col(16);
				}
			}
		}

		&.flip {
			@include media-breakpoint-up(lg) {
				.main {
					order: 2;
				}

				.sidebar {
					order: 1;
				}
			}
		}

		.resource_careers & {
			@include media-breakpoint-up(lg) {
				.main {
					@include make-col(7);
					padding-right: 15px;
				}

				.sidebar {
					@include make-col(9);
					padding-left: 15px;
				}
			}
		}

	}