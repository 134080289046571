.block {	//border: 2px solid red;	.wrap { border: 2px solid green; }
	padding: 30px;

	@include media-breakpoint-down(md) {
		.wrap+.wrap { margin-top: 15px; }
	}

}

	.wrap > *:first-child { margin-top: 0; }
	.wrap > *:last-child { margin-bottom: 0; }

	.block_title {
		margin-bottom: 15px;
		.block-title { margin: 0; }
	}

		.block-title {
			margin: 0 0 15px;
			@include font-size(24);
			font-weight: $body-weight-bolder;
			letter-spacing: -0.0525em;
			@include media-breakpoint-up(md) { @include font-size(32); }
		}


.block.corona {
	padding: 15px;
	border-top: 3px solid #222;
	background-color:#FFC107;
	@include media-breakpoint-up(md) {
		.flex_wrap {
			@include make-flex;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			> * {
				margin: 0 !important;
				display: inline-block;
				width: auto;
			}
			> *:first-child { padding-right: 30px; }
			.btn { white-space: nowrap; }
		}
	}
	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.wrap { @include make-col(14); }
	}
	@include media-breakpoint-up(xl) {
		.wrap { @include make-col(12); }
	}
}