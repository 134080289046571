// ===========================================
// BOOTSTRAP COMPONENT MIXINS - 3.3.7
// ===========================================

@mixin img-responsive($display: block) {
	display: $display;
	max-width: 100%; // Part 1: Set a maximum relative to the parent
	height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

@mixin transition-transform($transition...) {
	-webkit-transition: -webkit-transform $transition;
	 -moz-transition: -moz-transform $transition;
	   -o-transition: -o-transform $transition;
		  transition: transform $transition;
}

@mixin backface-visibility($visibility) {
	-webkit-backface-visibility: $visibility;
	 -moz-backface-visibility: $visibility;
		  backface-visibility: $visibility;
}

@mixin perspective($perspective) {
	-webkit-perspective: $perspective;
	 -moz-perspective: $perspective;
		  perspective: $perspective;
}

@mixin translate3d($x, $y, $z) {
	-webkit-transform: translate3d($x, $y, $z);
			transform: translate3d($x, $y, $z);
}

@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
	background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
	background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
	background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// ===========================================
// CUSTOM MIXINS
// ===========================================
@mixin transition($arg) {
	-webkit-transition: $arg;
	   -moz-transition: $arg;
		-ms-transition: $arg;
		 -o-transition: $arg;
			transition: $arg;
}

@mixin transform($arg) {
	-webkit-transform: $arg;
	   -moz-transform: $arg;
		 -o-transform: $arg;
			transform: $arg;
}

@mixin gdfancy-bg($arg) {
	background: $arg;
	background:   linear-gradient(45deg, rgba(black,0.18) 0%, transparent 25%, transparent 75%, rgba(black,0.18) 100%),
				  linear-gradient(135deg, rgba(black,0.09) 10%, transparent 45%, transparent 55%, rgba(black,0.09) 90%),
				  linear-gradient(45deg, transparent 20%, rgba(255,255,255,0.22) 35%, transparent 35%),
				  linear-gradient(45deg, transparent 60%, rgba(255,255,255,0.22) 75%, transparent 75%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.18) 40%, rgba($white,0.18) 60%, rgba($white,0) 85%),
				  $arg;

}

@mixin font-size($size, $base: 16) {
	font-size: $size * 1px;
	font-size: calculateRem($size, $base);
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity = $opacity-ie); //IE8
}

@mixin prefix($property, $value, $vendors: webkit moz ms o, $default: true) {
	@if $vendors {
		@each $vendor in $vendors {
			#{"-" + $vendor + "-" + $property}: #{$value};
		}
	}
	@if $default {
		#{$property}: #{$value};
	}
}


@mixin card($padding: 20px, $radius: 5px, $bg: $white) {

  margin: 0 0 1.5em;
  padding: $padding;
  border-radius: $radius;
  background-color: $bg;
  box-shadow: 0 20px 15px -20px rgba(0, 0, 0, 0.4);

}

@mixin grow($scale: '0.9', $transition: 'all 300ms') {
	transform: scale(unquote($scale));
	transition: unquote($transition);

	&:hover {
		transform: scale(1);
		transition: unquote($transition);
	}
}

@mixin align($vertical: true, $horizontal: false, $position: relative) {
	@if $position { position: $position; }
	@if $vertical { top: 50%; }
	@if $horizontal { left: 50%; }
	@if $vertical and $horizontal { transform: translateX(-50%) translateY(-50%); }
	@else if $vertical { transform: translateY(-50%); }
	@else { transform: translateX(-50%); }
}