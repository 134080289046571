.block_banner {
	text-align: center;
	border-top: 10px solid $color-primary;
	background-image: 
		url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dddddd' viewBox='0 0 360 120'%3E%3Cpath d='M358.7,114.1v-1.9h-3.6v-1.6h-1.5v-0.7h-3v0.7h-1v3.5h-1.5v-1.6h-0.3v-1h-4.5v1h-2.1v1.6h-1.6v-5.9h-2.1v4h-0.9 v-1h-3.3v1h-2.8v-1.7h-3.8v1.7h-3.4v-1.7h-5.5v2h-3.6v1.6h-3.2V113h-1.8v-4.4h-2.9v4.4h-2.1v-1.9h-2.8v1.9h-2.6v-1.8h-0.6v-1h-2.6v1 h-4.2v-2.7h-4.8V85.4h-1.5v-2.6h-10.6v2.6h-1.5v26.1h-0.9v2.7h-0.6v-50h-10.6v48.6h-0.7v-1.1h-1.2V81.1h-12.7v30.6h-1.8v1.5H243v-2 h-4.8v1.8H236v-1.1h-3.5v-1.1h-2.4v2.2h-1.8v-0.2h-1.7v0.2h-1v1.1h-2.1v-5.6h-4.7V76.3h-0.6v-4.9h-0.7v-5h-1.5v-2.9h-0.7v-1.5h-0.7 v-0.7l-0.8-0.7h-4.3l-0.8,0.7v0.7h-0.7v1.5h-0.7v2.9h-1.5v5h-0.7v4.9h-0.6v30h-1.2v1.5h-1.7V51.3h-13v59.1h-1.9v-44h-14v41.5h-2.4 v2.5H169v-3.9h-2.9V66.4h-14v24.1h-0.7V89H145v-2.5h-7.3V89h-0.9V65.7h-1.4V53.6H134v-5.8h-0.8V40H125v7.8h-0.8v5.8h-1.4v12.1h-1.4 v43.2h-0.7v-1.4h-1.5V82.1H115v-4.9h-10.3v4.9h-4.1v32h-1v-5.4h-4.1v2.2h-2.1v-3.2h-2.7V77h-9.1v34h-2.2v3.2H78v-5h-2.4V95.6v-25 h-21v25H49v13.6h-3.5v5h-2V113h-3.6v1.1h-2.8v-4.3h-4.7v3h-4.1v-4.3h-5.9V87h-2.2v-1.4h-4.3V87h-2.2v25.1h-2.2v2.1H0v5.9h360v-5.9 H358.7z'/%3E%3C/svg%3E"),
		linear-gradient(to top, #ddd 13px, transparent 13px);
	background-position: center bottom;
	background-repeat: no-repeat;

	@include media-breakpoint-up(md) {
		.row { align-items: center; }
		.banner_branding { @include make-col(4); padding: 0; text-align: left; }
		.banner_contact { @include make-col(12); margin-top: 0 !important; text-align: right; }
		.banner_nav { margin-top: 30px; }
	}

	@include media-breakpoint-up(lg) {
		.row { align-items: flex-start; }
		.banner_contact { margin-top: 30px !important; }
		.banner_nav {
			@include make-col(11);
			margin: -80px 0 0 auto;
		}
	}
	
	@include media-breakpoint-up(xl) {
		.banner_nav { @include make-col(10); }

	}

}

// BANNER BUTTON

.banner_button {
	text-align: left;
}


// ========================================================
// BANNER BRANDING
//.banner_branding {}
// ========================================================

	.branding-title {
		margin: 0;
		font-weight: normal;
		line-height: 0;
	}

	.branding-logo {
		display: inline-block;
		width: 100%;
		max-width: 240px;
		margin-top: -40px;
		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}

// ========================================================
// BANNER CONTACT	
// ========================================================
.banner_contact {
	strong {
		display: block;
		margin-bottom: 3px;
	}

	@include media-breakpoint-up(md) {
		@include make-flex;
		flex-wrap: nowrap;
		justify-content: flex-end;
		align-items: center;
		> div {
			margin: 0 0 0 30px;
		}
	}

}

.rating-wrapper {
	margin-bottom: 10px; 
}

.rating-item {
	display: inline-block;
	line-height: 0;
	@include grow;

	&:not(:last-child) {
		margin-right: 10px;
	}
}

	.rating-logo {
		width: 30px;
		height: 30px;
		margin-right: 5px;
		@include media-breakpoint-up(md) {
			width: 24px;
			height: 24px;
		}
	}

	.rating-stars {
		width: ((65 * 1.25) * 1px);
		height: ((12 * 1.25) * 1px);
		fill: $color-review;
		@include media-breakpoint-up(md) {
			width: ((65 * 1.5) * 1px);
			height: ((12 * 1.5) * 1px);
		}
	}

	.rating-title {
		@include font-size(13);
		line-height: 1em;
	}

//.contact-wrapper {}

.contact-title {
	font-weight: $body-weight-bold;
	line-height: 1em;
	letter-spacing: -0.0425em;
	color: $color-secondary;
}

.contact-phone {
	@include font-size(24);
	font-weight: $body-weight-bolder;
	line-height: 1em;
	letter-spacing: -0.0475em;
}

// ========================================================
// BANNER NAVIGATION	
// ========================================================
.banner_nav {
	margin-top: 30px;
	padding: 0;
}