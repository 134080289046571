.gallery {
    overflow: hidden;
    margin-bottom: 1em;
    img:not(:last-child) { margin-bottom: 15px; }
}
    // G3

    .g3-md {
        @include media-breakpoint-only(md) {
            > img, > a, > .img-captioned {
                display: block;
                float: left;
                width: ((100 - (3 - 1)) / 3) * 1%;
                margin-right: 1%;
                &:nth-child(3n-2) { clear: both; }
                &:nth-child(3n) { float: right; margin-right: 0; }
            }
        }
    }

.gallery {
	overflow: hidden;
	margin-bottom: 1em;
}

.gallery_lightbox {
	>a {
		display: block;
		transition: all ease-in-out 300ms;
		overflow: hidden;
		margin-bottom: 2%;
		border: 3px solid transparent;

		img {
			display: block;
			width: 100%;
			transition: transform ease-in-out 200ms;
		}

		&:hover {
			transition: all ease-in-out 300ms;
			border-color: $color-secondary;

			img {
				transition: transform ease-in-out 200ms;
				transform: scale(1.2);
			}
		}

	}
}

// G3

.g2 {
	@include media-breakpoint-down(sm) {
		>img, >a, >.img-captioned, > picture {
			display: block;
			float: left;
			width: ((100 - (2 - 1)) / 2) * 1%;
			margin-right: 1%;
			&:nth-child(2n-1) { clear: both; }
			&:nth-child(2n) {
				float: right;
				margin-right: 0;
			}
		}
	}
}

.g3 {
	@include media-breakpoint-down(sm) {
		>img, >a, >.img-captioned {
			display: block;
			float: left;
			width: ((100 - (3 - 1)) / 3) * 1%;
			margin-right: 1%;
			&:nth-child(3n-2) { clear: both; }
			&:nth-child(3n) {
				float: right;
				margin-right: 0;
			}
		}
	}
}

.g4-md-only {
	@include media-breakpoint-only(md) {
		>img, >a, >.img-captioned, > picture {
			display: block;
			float: left;
			width: ((100 - (4 - 1)) / 4) * 1%;
			margin-right: 1%;
			&:nth-child(4n-3) { clear: both; }
			&:nth-child(4n) {
				float: right;
				margin-right: 0;
			}
		}
	}
}

.g6-md {
	@include media-breakpoint-up(md) {
		>img, >a, >.img-captioned {
			display: block;
			float: left;
			width: ((100 - (6 - 1)) / 6) * 1%;
			margin-right: 1%;

			&:nth-child(6n-5) {
				clear: both;
			}

			&:nth-child(6n) {
				float: right;
				margin-right: 0;
			}
		}
	}
}

