.front_jumbo {

	background-image: repeating-conic-gradient(from 0deg,
		transparent 0deg 15deg,
		#e5e5e5 15deg 30deg);

	.wrap { padding: 0; }
	
	@include media-breakpoint-up(lg) {
		.jumbo_list { padding-top: 15px; }
	}

}

/* .jumbo_conversion {
	@include media-breakpoint-up(lg) {
		position: relative;
		.conversion_text {
			position: absolute;
			z-index: 2;
			bottom: 75px;
			padding: 10px 20px;
			width: 100%;
			margin-bottom: 0;
			background-color: rgba($white, 0.8);
		}
	}
} */

.conversion_text {
	margin-bottom: 30px;
	text-align: center;
	p { margin-bottom: 0; }
	@include media-breakpoint-up(md) {
		@include make-flex;
		flex-wrap: nowrap;
		align-items: center;
		.conversion-text {
			text-align: left; 
			width: 100%; 
		}
		.conversion-button {
			margin-top: 0;
			padding-left: 30px;
			width: auto;
			white-space: nowrap;
		}
	}
	@include media-breakpoint-up(lg) {
		justify-content: center;
		.conversion-text { width: auto; }
	}
}

	.conversion-text p {
		font-weight: $body-weight-bold;
		line-height: 1.25em;
		letter-spacing: -0.0325em;
		@include media-breakpoint-only(lg) { @include font-size(15); }
		@include media-breakpoint-up(lg) {
			font-weight: $body-weight-bolder;
			letter-spacing: -0.0475em;
		}
	}

	.conversion-button { margin-top: 10px; line-height: 1em; }

