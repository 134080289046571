@mixin widget-color($color: #333) {
	border-color: $color;
	.widget-title { color: $color; }
	a:not(.menu-link, .btn) { color: $color !important; }
}

.widget {
	margin-bottom: 30px;
	padding: 15px;
	@include font-size(16);
	line-height: 1.275em;
	border-radius: 6px;
	border: 2px solid #ccc;
	background-color: $white;

	&.widget-primary { @include widget-color($color-primary); }
	&.widget-secondary { @include widget-color($color-secondary); }
	&.widget-highlight { @include widget-color($color-highlight); }
	&.widget-accent { @include widget-color($color-accent); }

	> *:first-child { margin-top: 0; }

	> *:last-child { margin-bottom: 0; }

}

	.widget-title {
		margin: 15px 0 10px;
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0375em;
	}


.widget_plain {
	padding: 0;
	border: none;
	box-shadow: none;
	background-color: transparent;
}


.widget-rates {
	max-width: 300px;
	margin: 0 auto 30px;
	@include font-size(20);
	font-weight: $body-weight-bolder;
	text-transform: uppercase;
	line-height: 1em;
	letter-spacing: -0.0325em;
	p {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		//justify-content: center;
		b {
			display: inline-block;
			padding: 5px 10px;
			margin-right: 10px;
			font-size: 190%;
			font-weight: $body-weight-normal;
			line-height: 1em;
			background-color: $color-primary;
			color: $white;
		}
		em { font-style: normal; }
	}
	@include media-breakpoint-up(lg) { max-width: none; }
}

.widget-usa {
	max-width: 360px;
	margin: 0 auto 30px;
	position: relative;
	img { border-radius: 6px; }
	p {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		padding: 15px 10px;
		background-color: rgba(white, .9);
		font-size: 20px;
		font-weight: $body-weight-bold;
		text-align: center;
		line-height: 1em;
		letter-spacing: -.0325em;
		color: #031d60;
		b { font-weight: $body-weight-bolder; color: #db0007; }
	}
}
